import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section, Icon, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu } from "@quarkly/components";
import { MdHelp } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index11"} />
		<Helmet>
			<title>
				CloudCopy
			</title>
			<meta name={"description"} content={"Самый легкий способ перенести ваши файлы из облака в облако."} />
			<meta property={"og:title"} content={"CloudCopy"} />
			<meta property={"og:description"} content={"Самый легкий способ перенести ваши файлы из облака в облако."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Section>
			<Box
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
			>
				<Text margin="0" md-margin="0px 0 20px 0" text-align="left" font="--lead">
					CloudCopy
				</Text>
				<Menu
					display="flex"
					justify-content="center"
					font="--base"
					font-weight="700"
					md-flex-direction="column"
					md-align-items="center"
				>
					<Override slot="link" text-decoration="none" color="--dark" padding="6px 12px" />
					<Override slot="link-active" color="--primary" />
					<Override slot="item" padding="6px" />
				</Menu>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Q&A
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Внимание! Бэта-версия!
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Дорогие друзья! Наш сервис еще достаточно молод. Мы начали его разработку для того, чтобы решить собственные задачи по переносу данных между облаками. Однако, востребованность сервиса не позволила нам долго использовать его только для своих нужд. Спешим сделать вашу жизнь чуточку легче!
					<br />
					<br />
					Обращаем ваше внимание, на то, что сервис работает в тестовом режиме. В процессе авторизации и копирования могу возникать ошибки. Данные на диске-источнике точно не пострадают, однако могут быть ситуации, когда часть файлов не будут скопированы. В случае возникновения проблем с сервисом, вопросов и предложений, пожалуйста свяжитесь с нами!
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Как оплатить Google Диск в 2023 году?
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					В связи с санкциями, оплатить подписку на Гугл Диск из России достаточно сложно. Оплата с помощью карт выпущенных российскими банками не проходит, поэтому, для самостоятельной оплаты облачного сервиса потребуется найти иностранную карту. Подойдут карты стран ближнего зарубежья, однако далеко не все россияне их имеют. В таком случае, выход может быть в переезде на российский облачный сервис. Благо, таких сервисов давольно много, а качество предоставляемых ими услуги зачастую не уступает зарубежным аналогам.
					<br />
					<br />
					Сервис CloudCopy поможет вам легко и быстро перенести все данные из вашего хранилища Гугл Диск на российский сервис, например Яндекс Диск.
				</Text>
			</Box>
			<Box
				margin="36px 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 36px 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Для чего нужен CloudCopy?{" "}
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Бывают ситуации, когда необходимо перенести или скопировать свои данные из одного облачного сервиса в другой. Распространенным кейсом является невозможность оплатить зарубежное хранилище — OneDrive, Google Drive, Dropbox или желание сделать резервную копию данных, хранящихся в облаке. Для того, чтобы скопировать данные с одного облака на другое, необходимо использовать свой компьютер — сначала скачать все на него, а потом, «залить» в новое облако. Это не проблема, если у вас небольшой объем данных. Однако, если на вашем облачном диске много гигабайт важных данных, перенос может быть давольно проблематичным — вам нужно найти место для временного хранения переносимых данных, скачать все на свой жесткий диск, используя оплаченный трафик, а потом закачать в новое хранилище. Сделать это на мобильном устройстве практически невозможно.
					<br />
					<br />
					Сервис CloudCopy позволяет копировать данные между вашими облачными хранилищами. Копирование данных происходит с использованием нашего сервера, что позволяет не использовать ресурсы вашего устройства. Кроме того, скорость подключения сервера обычно выше, чем у обычных устройств, что позволяет сделать операцию дешевле и быстрее.
				</Text>
			</Box>
			<Box
				margin="36px 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 36px 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Какие облачные сервисы поддерживаются?{" "}
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					В настоящий момент сервис CloudCopy поддерживает следующие облачные хранилища:
					<br />
					<br />
					Яндекс Диск
					<br />
					Google Диск{" "}
					<br />
					Microsoft OneDrive
					<br />
					Dropbox
					<br />
					<br />
					В наших планах — увеличение количества поддерживаемых сервисов хранения данных.
				</Text>
			</Box>
			<Box
				margin="36px 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 36px 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Можно ли выбрать файлы для копирования?
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					В настоящий момент поддерживается только полное копирование всех файлов на устройстве из одного облако в другое.
					<br />
					<br />
					В будущем, мы планируем добавить возможность выбора файлов и папок для переноса.
				</Text>
			</Box>
			<Box
				margin="36px 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 36px 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Будут ли мои файлы удалены после копирования?
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Нет, сервис CloudCopy не удаляет никакие фалы на ваших облачных дисках. Если вам нужно освободить место в облачном хранилище, вы можете сделать это в ручном режиме.{" "}
				</Text>
			</Box>
			<Box
				margin="36px 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 36px 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Что делать, если места на диске-приемнике не достаточно?
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Перед началом процедуры копирования данных мы определяем, достаточно ли места на принимающем диске. Если его размер меньше чем размер отправляемых данных операция будет отменена. Перенесите часть данных на свое устройство или в другой облачный сервис, после чего повторите попытку копирования.
				</Text>
			</Box>
			<Box
				margin="36px 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 36px 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Сколько стоит копирование данных?
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Стоимость копирования определяется на основании реального объема переносимых данных. Перед началом копирования мы измеряем точный размер данных на диске-источнике, и рассчитываем стоимость копирования исходя из цены 1 рубль за 1 Гигабайт копируемых данных. Таким образом, если на вашем диске 100 Гб. данных, вы заплатите за копирование 100 рублей. Минимальный размер платежа — 50 руб.
				</Text>
			</Box>
			<Box
				margin="36px 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 36px 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Как оплатить услуги сервиса?{" "}
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Оплатить услуги сервиса вы можете с помощью банковской карты. Наш сервис работает с платежным агрегатором...
				</Text>
			</Box>
			<Box
				margin="36px 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 36px 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Как узнать, что мои файлы скопированы?
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Вы можете следить за процессом копирования в вашем Личном Кабинете. Там отображается актуальная информация по всем вашим заказам.{" "}
				</Text>
			</Box>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0">
			<Menu
				display="flex"
				justify-content="center"
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
			</Menu>
			<Span text-decoration-line="none" variant="--base" color="--grey" hover-color="--primary">
				hello@cloudcopy.ru
			</Span>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6402f0dfb4ef240020702a75"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});