export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"6402f0dfb4ef240020702a7c",
				"6402f0dfb4ef240020702a7f",
				"64031fd93dedc4001fc81f06",
				"6403f3a83dedc4001fc8358e",
				'6403f3a83dedc4001fc8359e'
			]
		},
		"6402f0dfb4ef240020702a7c": {
			"id": "6402f0dfb4ef240020702a7c",
			"name": "404",
			"pageUrl": "404"
		},
		"6402f0dfb4ef240020702a7f": {
			"id": "6402f0dfb4ef240020702a7f",
			"name": "Главная",
			"pageUrl": "index"
		},
		"64031fd93dedc4001fc81f06": {
			"pageUrl": "index1",
			"id": "64031fd93dedc4001fc81f06",
			"name": "Как пользоваться"
		},
		"6403f3a83dedc4001fc8358e": {
			"pageUrl": "index11",
			"id": "6403f3a83dedc4001fc8358e",
			"name": "Вопрос-ответ"
		},
		"6403f3a83dedc4001fc8359e": {
			"pageUrl": "lk",
			"id": "6403f3a83dedc4001fc8359e",
			"name": "Личный кабинет"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"title": "CloudCopy",
			"description": "Самый легкий способ перенести ваши файлы из облака в облако.",
			"og:description": "Самый легкий способ перенести ваши файлы из облака в облако.",
			"og:title": "CloudCopy"
		}
	}
}