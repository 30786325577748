import React from "react";
import Index from "pages/index";
import Index1 from "pages/index1";
import Index11 from "pages/index11";
import Page404 from "pages/page404";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;

function RedirectToLK() {
    window.location.href = 'https://www.account.cloudcopy.ru'
    return null;
}

export default () => (
    <Router>
        <GlobalStyles />
        <Switch>
            <Route exact path='/' component={Index} />
            <Route exact path='/index' component={Index} />
            <Route exact path='/index1' component={Index1} />
            <Route exact path='/index11' component={Index11} />
            <Route exact path='/lk' component={RedirectToLK} />
            <Route component={Page404} />
        </Switch>
    </Router>
);
